.monitorank__down__button {
  margin-bottom: 30px;
}

.monitorank__zone {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.monitorank__button {
  width: 100%;
  min-width: 200px;
  min-height: 150px;
  border-radius: 6px;
  background-color: var(--color-back);
  border: 2px solid var(--color-ice);
  font-size: 20px;
  font-weight: 550;
  color: var(--color-front-light);
}

.monitorank__button:hover {
  background-color: var(--color-ice-light);
}

.monitorank__button--off {
  color: var(--color-ice);
}

.monitorank__button--off:hover {
  background-color: var(--color-back);
}

.monitorank__update {
  height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.monitorank__update__selection {
  overflow: auto;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.monitorank__separator {
  border: 1px solid var(--color-ice);
}

.monitorank__select-multiple {
  height: 100%;
}

.monitorank__update_add {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 4px;
  height: max-content;
}

.monitorank__loading {
  position: absolute;
  bottom: 2px;
  left: 5px;
  width: calc(100% - 10px);
}

@media (max-width: 600px) {
  .monitorank__select-multiple {
    max-width: 40vw;
  }
  .monitorank__select-multiple > .select-multiple__input {
    max-width: 35vw;
  }
  .monitorank__button {
    min-width: 150px;
    min-height: 120px;
  }
}
