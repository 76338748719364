.select-multiple__result {
  min-height: 39px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.select-multiple__dropdown {
  width: 40px;
  flex: 0 0 auto;
  border: none;
  font-size: 25px;
  color: rgb(33, 33, 33);
}

.select-multiple__dropdown::after {
  content: ">";
  display: inline-block;
  transform: rotate(0deg);
  transition: 0.3s ease all;
}

.select-multiple__dropdown--down::after {
  content: ">";
  display: inline-block;
  transform: rotate(90deg);
  transition: 0.3s ease all;
}

.select-multiple__selected-elements {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px;
}

.select-multiple__options {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: left;
}

.select-multiple__options--down {
  height: auto;
}

.select-multiple__option {
  margin: 0 10px;
}

.select-multiple__option:hover {
  background-color: var(--color-ice-light);
}

.select-multiple__input {
  padding: 5px;
}

.select-multiple-element {
  background-color: var(--color-primary);
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.select-multiple__title {
  font-size: 17px;
  font-weight: 550;
}

@media (max-width: 695px) {
  .select-multiple__title {
    font-size: 15px;
    font-weight: 550;
  }
  .select-multiple__option {
    margin: 0 5px;
  }
  .checkbox__label {
    font-size: 13px !important;
  }
  .select-multiple__input {
    margin: 0 5px;
  }
}
