.gestion-scrollbar {
  width: 30%;
  height: 100%;
  flex: 0 0 auto;
  display: flex;
  gap: 7px;
  flex-direction: column;
  background-color: var(--color-back);
  color: var(--color-front);
  font-size: 14px;
  font-family: "JetBrains Mono", monospace;
  border: 1px solid var(--color-ice);
  border-radius: 8px;
  padding-right: 1px;
}

.gestion-scrollbar__domains {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--color-front);
  font-size: 14px;
  font-family: "JetBrains Mono", monospace;
  overflow-y: auto;
}

.gestion-scrollbar__search {
  position: relative;
  width: auto;
  padding: 5px;
}

.gestion-scrollbar__search > div {
  min-width: auto;
}

.gestion-scrolbar__icon {
  width: 20px;
  height: 20px;
  fill: var(--color-front);
}

.gestion-scrollbar__button {
  background-color: var(--color-back);
  color: var(--color-front);
  border: none;
  font-size: 17px;
  text-align: left;
  padding: 6px 14px;
}

.gestion-scrollbar__button--deleted {
  color: var(--color-ice);
  text-decoration: line-through;
}

.gestion-scrollbar__button:hover {
  background-color: var(--color-ice-light);
}

.gestion-scrollbar__button--selected {
  color: var(--color-primary);
}

.gestion-scrolbar__results {
  text-align: center;
  color: gray;
}

@media (max-width: 600px) {
  .gestion-scrollbar {
    position: absolute;
    top: 75px;
    height: max-content;
    z-index: 3;
    width: calc(100% - 20px);
  }
  .gestion-scrollbar__domains {
    height: max-content;
    max-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    color: var(--color-front);
    font-size: 14px;
    font-family: "JetBrains Mono", monospace;
    overflow-y: auto;
  }
}
