.gestion-spe {
  width: 100%;
  flex: 1 1 65%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.gestion-spe__link,
.gestion-spe__specificities {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-back);
  color: var(--color-front);
  font-size: 16px;
  font-family: "JetBrains Mono", monospace;
  border: 1px solid var(--color-ice);
  overflow-y: auto;
}

.gestion-spe__link {
  height: 40%;
  border-start-end-radius: 8px;
  border-start-start-radius: 8px;
}

.gestion-spe__center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.gestion-spe__specificities {
  position: relative;
  height: 60%;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
}

.gestion__link {
  text-align: center;
  text-decoration: underline;
}

.gestion-spe__copy {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: initial;
  border: none;
}

.gestion-spe__add__button {
  position: absolute;
  top: 12px;
  right: 15px;
  width: 22px;
  height: 22px;
  background-color: initial;
  border: none;
}

.gestion-spe__add {
  position: absolute;
  top: -12px;
  right: -7.5px;
  fill: none;
  stroke: var(--color-front);
  stroke-width: 2;
}

.gestion-spe__copy:hover {
  cursor: pointer;
}

.gestion_spe__copy-icon {
  fill: var(--color-front);
}

.gestion-spe__content {
  width: 100%;
  height: 100%;
  max-height: 60px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin: 15px 0 15px 0;
}

.gestion-spe__content__spe {
  width: 100%;
  text-align: center;
  color: var(--color-ice);
  border-right: 2px solid var(--color-ice);
}

.gestion-spe__content__spe:last-of-type {
  border-right: none;
}

.gestion-spe__content__spe__name {
  color: var(--color-front);
  margin-top: 5px;
  text-align: left;
  padding-left: 10px;
}

.gestion-spe__content__dropdown {
  position: relative;
  width: 35px;
  height: 35px;
}
