.menu {
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: var(--color-card);
  padding: 10px;
  border-bottom: 1px solid var(--color-ice-light);
  z-index: 3;
}

.menu__navigation {
  width: 100%;
  max-width: var(--content-width);
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
  overflow: auto;
}

.menu__navigation > * {
  flex: 0 0 auto;
  width: auto;
}

.menu__img {
  width: 38px;
}

.menu__logout {
  margin-left: auto;
}

@media (max-width: 600px) {
  .menu {
    z-index: 5;
  }
}
