.checkbox {
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
}

.checkbox:hover .checkbox__div {
  background: var(--color-primary);
}

.checkbox__input {
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox__input:checked + div > .checkbox__check {
  stroke-dashoffset: 0;
}

.checkbox__input:checked + div > .checkbox__check-left {
  stroke-dashoffset: 0;
}

.checkbox__input:checked + div {
  background: var(--color-primary);
}

.checkbox__check {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 25px;
  height: 25px;
  stroke: #f9f9f9;
  fill: none;
  stroke-dasharray: 25;
  stroke-dashoffset: 25;
  stroke-linecap: round;
  stroke-width: 0.2em;
  transition: background 0.1s, stroke-dashoffset 0.2s;
}

.checkbox__check-left {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 25px;
  height: 25px;
  stroke: #f9f9f9;
  fill: none;
  stroke-dasharray: 25;
  stroke-dashoffset: 25;
  stroke-linecap: round;
  stroke-width: 0.2em;
  transition: background 0.1s, stroke-dashoffset 0.2s 0.2s;
}

.checkbox__div {
  position: relative;
  width: 20px;
  height: 20px;
  border: 0.2rem solid var(--color-primary);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0.5px 0.5px 2px black;
}

.checkbox__label {
  margin-left: 4px;
}
