.tabs {
  width: max-content;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 12px;
  padding: 5px;
  border-radius: 8px;
  background-color: var(--color-ice-light);
}

.tabs__button {
  background-color: var(--color-ice-light);
  color: var(--color-front);
  padding: 6px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  position: relative;
}

.tabs__button:hover {
  background-color: var(--color-ice);
  cursor: pointer;
}

.tabs__button:focus:hover {
  box-shadow: var(--focusring);
}

.tabs__button--selected {
  background-color: var(--color-primary);
  color: var(--color-back);
}
.tabs__button--selected:hover {
  background-color: var(--color-primary);
}

.ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: rgba(255, 255, 255, 0.578);
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
