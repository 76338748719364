.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app > :last-child {
  flex: 1 1 auto;
  margin: 10px 0;
  width: 100%;
}

.app [role="tabpanel"] {
  height: 100%;
}
