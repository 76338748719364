.gestion-goals {
  flex: 1 1 100%;
  width: 100%;
  display: flex;
}

.gestion-goals__event,
.gestion-goals__page {
  --title-height: 42px;

  position: relative;
  width: 100%;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-back);
  color: var(--color-front);
  font-size: 16px;
  font-family: "JetBrains Mono", monospace;
  border: 1px solid var(--color-ice);
}

.gestion-goals__event {
  border-radius: 8px 0 0 8px;
}

.gestion-goals__page {
  border-radius: 0 8px 8px 0;
  border-left: 0;
}

.gestion-goals__title {
  height: var(--title-height);
  margin: 0;
  padding: 8px;
  text-align: center;
}

.gestion-goals__list {
  position: absolute;
  top: var(--title-height);
  left: 0;
  width: 100%;
  height: calc(100% - var(--title-height));
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.gestion-goals__add {
  position: absolute;
  top: 5px;
  right: 5px;
}

.gestion-goals__add__icon {
  fill: none;
  stroke: var(--color-front);
  stroke-width: 2;
}
