.gestion-users {
  width: 100%;
  max-width: var(--content-width);
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 80px;
  margin-top: 30px;
  font-weight: 550;
}

.gestion-users__list {
  display: flex;
  flex-direction: column;
  font-weight: initial;
}

.gestion-users__list__line:first-child {
  border-start-end-radius: 8px;
  border-start-start-radius: 8px;
  border-top: 1px solid var(--color-ice);
}

.gestion-users__list__line {
  border-right: 1px solid var(--color-ice);
  border-left: 1px solid var(--color-ice);
  border-bottom: 1px solid var(--color-ice);
  display: flex;
  justify-content: left;
  overflow: hidden;
}

.gestion-users__list__line:last-child {
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  border-bottom: 1px solid var(--color-ice);
}

.gestion-users__list__line:hover {
  background-color: var(--color-ice-light);
}

.gestion-users__list__line__name {
  width: 40%;
  padding: 20px;
  border-start-start-radius: 8px;
}

.gestion-users__list__line__token {
  width: 30%;
  border-left: 1px solid var(--color-ice);
  padding: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.gestion-users__list__line__date {
  width: 30%;
  border-left: 1px solid var(--color-ice);
  padding: 20px;
  border-start-end-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
}

.gestion-users__copy {
  position: absolute;
  top: calc(50% - 10px);
  right: 2px;
  border-radius: 2px;
}

.gestion-users__title {
  display: flex;
  justify-content: space-between;
}

.gestion-users__title__button {
  background-color: var(--color-ice-light);
  color: var(--color-front);
  border: 1px solid var(--color-ice);
  padding: 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gestion-users__title__button__icon {
  width: 20px;
  height: 20px;
  fill: var(--color-front-light);
  margin-left: -3px;
}

.gestion-users__list__line__date__buttons {
  position: absolute;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gestion-users__list__line__date__button {
  border: none;
  background: none;
}

.gestion-users__list__line:hover
  > .gestion-users__list__line__date
  > .gestion-users__list__line__date__button {
  background-color: var(--color-ice-light);
}

.gestion-users__list__line__date__icon {
  width: 20px;
  height: 20px;
}

.gestion-users__list__line__date__button:hover
  > .gestion-users__list__line__date__icon {
  fill: var(--color-primary);
}

@media (max-width: 600px) {
  .gestion-users__list__line__name {
    width: 40%;
    padding: 20px 5px;
    border-start-start-radius: 8px;
  }

  .gestion-users__list__line__token {
    width: 30%;
    border-left: 1px solid var(--color-ice);
    padding: 20px 5px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .gestion-users__list__line__date {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .gestion-users__list__line__date__buttons {
    position: relative;
    right: 0;
  }
}
