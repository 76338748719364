.popup-title {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.popup-choose-type {
  width: 100%;
}

.popup-choosed-type {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.popup-add-type {
  margin: 0 10px;
  background-color: var(--color-back);
  padding: 6px 0;
  padding-left: 15px;
  border-radius: 8px;
}

.popup-add-selectfield {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  max-height: 0rem;
  position: absolute;
  width: calc(100% - 20px);
  z-index: 11;
  overflow-y: auto;
}

.popup-add-selectfield > button {
  background-color: var(--color-back);
  font-size: 15px;
  padding: 3px;
  color: inherit;
  border: none;
}

.popup-name {
  margin: 0 10px;
}

.popup-validation {
  width: 50%;
  margin: 0 25%;
  margin-top: 5px;
}

.popup-add-selectfield > button:hover {
  background-color: var(--color-ice-light);
}

.popup-choose-type:hover > .popup-add-type {
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.popup-choose-type:hover > .popup-add-selectfield {
  animation: appear 0.25s forwards;
}

@keyframes appear {
  0% {
    max-height: 0rem;
  }
  25% {
    max-height: 1rem;
  }
  50% {
    max-height: 2rem;
  }
  75% {
    max-height: 3rem;
  }
  100% {
    max-height: 4rem;
  }
}

.popup__text {
  padding: 0 8px;
  margin-top: -10px;
}
