.permissions__back {
  width: 100vw;
  height: 100vh;
  backdrop-filter: brightness(35%);
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.permissions__zone {
  width: 50%;
  height: 70%;
  background-color: var(--color-back);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: auto;
}

.permissions__bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  background-color: var(--color-back);
}

.permissions__buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.permissions__select-multiple {
  height: 80%;
  width: 100%;
}

.permissions__select-multiple > div {
  height: 100%;
}

.permissions__select-multiple > div > div {
  overflow-y: auto;
  max-height: 100%;
}

@media (max-width: 600px) {
  .permissions__zone {
    width: calc(100vw - 20px);
  }
}
