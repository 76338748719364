.form {
  width: 100%;
  max-width: var(--content-width);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  padding: 30px 10px;
}

@media (min-width: 1220px) {
  .form {
    padding: 30px 0;
  }
}

.form > :nth-child(4) {
  margin: 0;
}

.form__domains {
  min-height: 6rem;
  padding: 10px;
  background-color: var(--color-ice-light);
  color: var(--color-front);
  font-family: "JetBrains Mono", monospace;
  font-size: 14px;
}

.form__options {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form__goals {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (min-width: 500px) {
  .form__goals {
    flex-direction: row;
    gap: 30px;
  }
}

.form__goals__textarea {
  flex: 1 1 auto;
  padding: 10px;
  background-color: var(--color-ice-light);
  color: var(--color-front);
  font-family: "JetBrains Mono", monospace;
  font-size: 14px;
}

.form__goals__textarea--events {
  min-height: 15rem;
}

.form__goals__textarea--pages {
  min-height: 5rem;
}

.form__validate {
  margin: 0 auto;
}

.form__output {
  height: 24rem;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--color-ice-light);
  color: var(--color-front);
  font-size: 14px;
  font-family: "JetBrains Mono", monospace;
  border: 1px solid var(--color-ice);
  border-radius: 8px;
  overflow-y: auto;
}

.form__log--error {
  color: rgb(202, 51, 28);
  font-style: bold;
}

.form__log--url {
  text-decoration: underline;
}

.form__selection {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
