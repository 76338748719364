:root {
  --content-width: 900px;
  --box-shadow: 0px 2px 15px rgba(169, 169, 178, 25%);
  --color-valraiso-fade: rgba(255, 60, 0, 10%);
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

@import "./components/common/popup.css";
@import "./components/common/dropdown.css";
@import "./components/common/tabs.css";
@import "./components/common/copied-button.css";
@import "./components/common/loader.css";
@import "./components/common/select-multiple/checkbox.css";
@import "./components/common/select-multiple/select-multiple.css";

@import "./components/app.css";
@import "./components/form.css";
@import "./components/page-handler/login-form.css";
@import "./components/page-handler/menu.css";
@import "./components/gestion/index.css";
@import "./components/gestion/gestion-scrollbar.css";
@import "./components/gestion/gestion-goals.css";
@import "./components/gestion/gestion-specificities.css";
@import "./components/gestion/gestion-specificity.css";
@import "./components/gestion/goal.css";
@import "./components/popups/add-goal.css";
@import "./components/popups/delete-domain.css";
@import "./components/popups/info-specificity.css";
@import "./components/gestion/users.css";
@import "./components/gestion/permissions.css";
@import "./components/popups/incomes-settings.css";
@import "./components/gestion/monitorank.css";
