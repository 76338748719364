.generic-popup__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(35%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 18;
}

.generic-popup__content {
  max-width: 50vh;
  max-height: 50vh;
  border-radius: 12px;
  background-color: var(--color-card);
  box-shadow: var(--card-shadow);
  z-index: 20;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.generic-popup__close-button {
  background-color: initial;
  border: none;
  position: absolute;
  top: -17px;
  right: -17px;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.generic-popup__close-icon {
  width: 40px;
  height: 40px;
  fill: var(--color-ice-light);
  stroke-width: 2;
}

.generic-popup__close-icon:hover {
  fill: var(--color-ice);
}

.generic-popup__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 5px;
}
