.dropdown {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dropdown__toggle {
  width: 35px;
  height: 30px;
  background-color: var(--color-back);
  border: 1px solid var(--color-ice);
  border-radius: 8px;
}

.dropdown__toggle:hover {
  background-color: var(--color-ice);
}

.dropdown__toggle-icon {
  fill: var(--color-front);
}

.dropdown__options {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-ice);
  border-radius: 8px;
  box-shadow: 0px 0px 3px var(--color-ice);
}

.dropdown__option {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-ice-light);
  border: 1px solid var(--color-ice-light);
  color: var(--color-front);
}

.dropdown__option:last-child {
  border-end-end-radius: 6px;
  border-end-start-radius: 6px;
}

.dropdown__option:first-child {
  border-start-start-radius: 6px;
  border-start-end-radius: 6px;
}

.dropdown__option:hover {
  background-color: var(--color-ice);
  border: 1px solid var(--color-ice);
}

.dropdown__option-icon {
  fill: var(--color-front);
}
