.incomes-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 20px;
  width: 60vw;
  min-width: 300px;
  gap: 20px;
  position: relative;
}

.incomes-settings__popup > .generic-popup__content {
  max-width: 100vw;
  max-height: 100vh;
}

.incomes-settings__type {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  border: 3px solid var(--color-back);
}

.incomes-settings__type--choosen {
  border: 3px solid var(--color-primary);
}

.incomes-settings__type__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.incomes-settings__type__buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.incomes-settings__type--disabled > div {
  color: var(--color-ice);
}

.incomes-settings__type__update {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.incomes-settings__type__update__content {
  display: flex;
  position: relative;
}

.incomes-settings__type__update__column {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
}

.incomes-settings__type__update__grid {
  border: none;
  padding: 15px;
  background-color: var(--color-back);
  color: var(--color-front);
  border-bottom: 1px solid var(--color-ice);
}

.incomes-settings__type__update__header {
  background-color: var(--color-ice-light);
  text-align: center;
  border-top: 1px solid var(--color-ice);
  border-bottom: 1px solid var(--color-ice);
  border-left: 1px solid var(--color-ice);
}

.incomes-settings__type__update__column b,
.incomes-settings__type__update__column input {
  border-left: 1px solid var(--color-ice);
}

.incomes-settings__type__update__column:last-of-type b,
.incomes-settings__type__update__column:last-of-type input {
  border-right: 1px solid var(--color-ice);
  border-left: 1px solid var(--color-ice);
}

.incomes-settings__type__update__buttons {
  display: flex;
  width: 100%;
  gap: 40px;
  padding: 0 20px;
}

.incomes-settings__type__update__buttons > button {
  flex: 1 1 100%;
}

.incomes-settings__type__update__new__button {
  border: none;
  background: none;
  position: absolute;
  bottom: -25px;
  align-self: center;
  width: 100%;
}

.incomes-settings__type__update__new__button > svg {
  fill: var(--color-ice);
}

.incomes-settings__type__update__new__button > svg:hover {
  fill: var(--color-front);
  cursor: pointer;
}

.incomes-settings__type__update__title {
  border: none;
  padding: 3px;
  font-size: 17px;
  font-weight: bold;
  width: max-content;
}
