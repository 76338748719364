.goal {
  display: flex;
  justify-content: space-between;
  margin: 3px;
  align-items: center;
  text-align: left;
}

.goal--deleted {
  color: var(--color-ice);
  text-decoration: line-through;
}

.goal:hover {
  background-color: var(--color-ice-light);
}

.goal__options {
  display: none;
  border: none;
  background-color: var(--color-ice-light);
}

.goal__options:hover {
  border: none;
}

.goal:focus-within {
  z-index: 4;
}

.goal:focus-within .goal__options,
.goal:hover .goal__options {
  display: initial;
}

.goal__icons {
  position: relative;
  width: 35px;
  height: 30px;
}

.goal__parameters {
  position: absolute;
  top: 0px;
  right: 0px;
}
