.login-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form__inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 26px;
  background-color: var(--color-ice-light);
  border-radius: 8px;
}

.login-form__header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.login-form__logo {
  flex: 0 0 50px;
  height: 50px;
}

.login-form__title {
  margin: 0;
  text-align: center;
  font-weight: 400;
}
