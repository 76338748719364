.popup-delete-text {
  text-align: center;
  margin: 20px 10px;
  line-height: 1.5rem;
}

.popup-delete-text-domain {
  font-weight: bold;
  text-decoration: underline;
}
