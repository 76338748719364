.popup-spe-info {
  padding: 5px 10px;
  max-height: 300px;
  margin: 0 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.popup-spe-info__span {
  width: 100%;
  padding: 3px 0 3px 15px;
}
