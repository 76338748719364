.gestion__zone {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.gestion {
  width: 100%;
  max-width: var(--content-width);
  height: calc(100vh - 100px);
  display: flex;
  gap: 40px;
  padding: 0px 10px;
  margin: 0 auto;
}

.gestion__title {
  text-align: center;
  padding: 4px 0;
  font-size: 24px;
  font-weight: bold;
  color: var(--color-primary);
  position: relative;
}

.gestion__infos {
  width: 70%;
  height: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  gap: 25px;
}

.gestion__without-domain {
  width: 100%;
  height: 100%;
  flex: 0 1 auto;
  border: dashed var(--color-ice-light);
  display: table;
  text-align: center;
  margin-top: 10px;
}

.gestion__without-domain > span {
  display: table-cell;
  vertical-align: middle;
  font-size: 26px;
  color: var(--color-ice);
}

.gestion__update {
  margin-bottom: -35px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.gestion__update__button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-ice);
}

.gestion__update__button__icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.gestion__update__button:hover {
  color: var(--color-front);
}

.gestion__parameters {
  position: absolute;
  top: 0px;
  right: 0;
}

.gestion-pagination {
  top: 75px;
  position: fixed !important;
}

@media (max-width: 1080px) {
  .gestion-pagination {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .gestion {
    padding-top: 100px;
    height: calc(130vh - 100px);
  }
  .gestion__infos {
    width: 100%;
    height: 100%;
  }
}
