.gestion-spe__specificity {
  box-shadow: 0.5px 0.5px 1.5px 1.5px var(--color-ice);
  border-radius: 4px;
  padding: 10px 20px;
  text-align: center;
  position: relative;
  color: var(--color-ice);
  font-weight: bold;
}

.gestion-spe__specificity__remove {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 18px;
  height: 18px;
  border: none;
  background-color: initial;
}

.gestion-spe__specificity__remove__icon {
  position: absolute;
  top: -2px;
  right: 1px;
  width: 16px;
  fill: var(--color-front);
}

.gestion-spe__specificity__remove:hover
  > .gestion-spe__specificity__remove__icon {
  fill: var(--color-primary);
  cursor: pointer;
}
.gestion-spe__specificity__name {
  color: var(--color-front);
  margin-top: 5px;
  margin-bottom: -5px;
  text-align: left;
  font-weight: initial;
}

.gestion-spe__specificity:hover {
  box-shadow: 0.5px 0.5px 1.5px 1.5px var(--color-primary);
  cursor: pointer;
}
